import { Progress } from 'react-async-utils';
import * as f from './fetchable';
export var asyncToFetchable = function asyncToFetchable(asyncData) {
  switch (asyncData.progress) {
    case Progress.Init:
      return f.init;

    case Progress.InProgress:
      return f.fetching;

    case Progress.Success:
      return f.success(asyncData.payload, asyncData.invalidated);

    case Progress.Error:
      return f.error(asyncData.error);
  }
};