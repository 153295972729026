export var safeEnv = function safeEnv(envName) {
  var env = process.env[envName];

  if (env != null) {
    return env;
  }

  throw new Error("Required ".concat(envName, " env variable not defined. Check dotenv files."));
};
export var safe = function safe(value) {
  if (value != null) {
    return value;
  } else {
    throw new Error('Unexpected unsafe value');
  }
};
export var safeGet = function safeGet(map, key) {
  var value = map.get(key);

  if (value != null) {
    return value;
  }

  throw new Error("Unexpected unsafe value for map with key ".concat(JSON.stringify(key)));
};