var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { DatePicker as AntdDatePicker, Input as AntInput, Select as AntSelect } from 'antd';
import { ErrorMessage as FormikErrorMessage, // FastField as FormikFastField,
Field as FormikField } from 'formik';
import React, { useCallback } from 'react';
import { RemoteSelect } from './fieldComponents/RemoteSelect';

var isStringArray = function isStringArray(array) {
  return typeof array[0] === 'string';
};

export var SchemaFormikField = function SchemaFormikField(_a) {
  var _b = _a.fieldSchema,
      key = _b.key,
      name = _b.name,
      label = _b.label,
      placeholder = _b.placeholder,
      required = _b.required,
      readonly = _b.readonly,
      description = _b.description,
      onChange = _b.onChange,
      _c = _b.controlType,
      controlType = _c === void 0 ? {
    type: 'text'
  } : _c,
      validate = _b.validate,
      setFieldValue = _a.setFieldValue;
  var handleChange = useCallback(function (value) {
    onChange ? onChange(value) : setFieldValue(name, value);
  }, [onChange, setFieldValue]);
  var validateWithRequired = useCallback(function (value) {
    return required && (value == null || typeof value === 'string' && !value.trim()) ? 'Required' : validate && validate(value);
  }, [required, validate]);
  return React.createElement("div", null, React.createElement("div", {
    style: {
      marginTop: '1rem',
      display: 'flex',
      alignItems: 'center'
    }
  }, React.createElement("label", {
    htmlFor: name,
    style: {
      flex: '0 0 10rem',
      marginBottom: 0
    }
  }, label, required && !readonly && '*'), React.createElement(FormikField, {
    key: key,
    name: name,
    validate: validateWithRequired,
    render: function render(_a) {
      var field = _a.field;
      var value = field.value;

      if (readonly && !value) {
        return '-';
      }

      switch (controlType.type) {
        case 'text':
          if (readonly) {
            return field.value;
          }

          return React.createElement(AntInput, __assign({
            id: name,
            type: controlType.inputType
          }, field, {
            maxLength: controlType.maxLength,
            onChange: onChange || field.onChange,
            placeholder: placeholder,
            // required={required}
            disabled: readonly
          }));

        case 'select':
          var options = controlType.options;

          if (readonly) {
            if (isStringArray(options)) {
              return value;
            } else {
              var option = options.find(function (o) {
                return o.value === field.value;
              });
              return option ? option.text : 'Unknown';
            }
          }

          return React.createElement(AntSelect, {
            id: name,
            disabled: readonly,
            value: field.value,
            placeholder: placeholder || 'Select...',
            notFoundContent: 'No Data',
            onChange: handleChange
          }, isStringArray(options) ? options.map(function (option) {
            return React.createElement(AntSelect.Option, {
              key: option,
              value: option
            }, option);
          }) : options.map(function (option) {
            return React.createElement(AntSelect.Option, {
              key: option.value,
              value: option.value
            }, option.text);
          }));

        case 'remote-select':
          return React.createElement(RemoteSelect, {
            controlType: controlType,
            name: name,
            placeholder: placeholder,
            readonly: readonly,
            setFieldValue: setFieldValue,
            value: value
          });

        case 'date':
          var showTime = controlType.showTime;
          var format = showTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY';

          if (readonly) {
            return value.format(format);
          }

          return React.createElement(AntdDatePicker, {
            id: name,
            onChange: handleChange,
            value: field.value,
            format: format,
            disabled: readonly,
            showTime: showTime
          });
      }
    }
  })), React.createElement("div", {
    style: {
      color: 'red'
    }
  }, React.createElement(FormikErrorMessage, {
    name: name
  })), !readonly && description);
};