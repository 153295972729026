var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

import { NetworkError, ServerError } from './errors';
export var simpleFetch = function simpleFetch(url, options) {
  return __awaiter(void 0, void 0, void 0, function () {
    var response, contentType, contentLength, error_1, body, error, json;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _a.trys.push([0, 2,, 3]);

          return [4
          /*yield*/
          , fetch(encodeURI(url), __assign({
            credentials: 'include'
          }, options))];

        case 1:
          response = _a.sent();
          contentType = response.headers.get('Content-Type');
          contentLength = response.headers.get('Content-Length');
          return [3
          /*break*/
          , 3];

        case 2:
          error_1 = _a.sent(); // tslint:disable-next-line: no-console

          console.error('simpleFetch network error:', error_1.message);
          throw new NetworkError();

        case 3:
          if (!!response.ok) return [3
          /*break*/
          , 5];
          return [4
          /*yield*/
          , response.text()];

        case 4:
          body = _a.sent();
          error = new ServerError(response.status, body); // tslint:disable-next-line: no-console

          console.error('simpleFetch result KO:', error);
          throw error;

        case 5:
          if (!(response.status === 204 || !contentType || !contentType.includes('application/json') || contentLength != null && contentLength === '0')) return [3
          /*break*/
          , 6];
          return [2
          /*return*/
          ];

        case 6:
          return [4
          /*yield*/
          , response.json()];

        case 7:
          json = _a.sent();
          return [2
          /*return*/
          , json];
      }
    });
  });
};
export var simpleBatchedFetch = function simpleBatchedFetch(items, getUrl, getOptions) {
  return __awaiter(void 0, void 0, void 0, function () {
    var _i, items_1, item;

    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          _i = 0, items_1 = items;
          _a.label = 1;

        case 1:
          if (!(_i < items_1.length)) return [3
          /*break*/
          , 4];
          item = items_1[_i];
          return [4
          /*yield*/
          , simpleFetch(getUrl(item), getOptions && getOptions(item))];

        case 2:
          _a.sent();

          _a.label = 3;

        case 3:
          _i++;
          return [3
          /*break*/
          , 1];

        case 4:
          return [2
          /*return*/
          ];
      }
    });
  });
};
export var postOptions = function postOptions(data) {
  return {
    body: data !== undefined ? JSON.stringify(data) : undefined,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST'
  };
};