import _slicedToArray from "C:\\vsts-agent\\_work\\2\\s\\ops-web\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";
import { expected, notFoundable, postOptions, simpleBatchedFetch, simpleFetch } from '@sim/simple-fetch';
import { getFullUrl } from '@sim/utils';
import { TargetType } from '../../types';
export var getTargetPrimaryTargets = function getTargetPrimaryTargets(_ref, target1Id) {
  var _ref2 = _slicedToArray(_ref, 2),
      target1Type = _ref2[0],
      target2Type = _ref2[1];

  return expected(notFoundable(simpleFetch, target1Type))(getFullUrl("/api/".concat(target1Type, "s/").concat(target1Id, "/primary").concat(target2Type, "s?api-version=1.0")));
};
export var postTargetPrimaryTargets = function postTargetPrimaryTargets(targetsTypes, target1Id, target2Ids) {
  return expected(notFoundable(simpleBatchedFetch, 'Device or User'))(target2Ids, targetsTypes[0] === TargetType.Device ? function (target2Id) {
    return getFullUrl("/api/devices/".concat(target1Id, "/primaryUsers/").concat(target2Id, "?api-version=1.0"));
  } : function (target2Id) {
    return getFullUrl("/api/devices/".concat(target2Id, "/primaryUsers/").concat(target1Id, "?api-version=1.0"));
  }, function () {
    return postOptions();
  });
};
export var deleteTargetPrimaryTargets = function deleteTargetPrimaryTargets(targetsTypes, target1Id, target2Ids) {
  return expected(notFoundable(simpleBatchedFetch, 'Device or User'))(target2Ids, targetsTypes[0] === TargetType.Device ? function (target2Id) {
    return getFullUrl("/api/devices/".concat(target1Id, "/primaryUsers/").concat(target2Id, "?api-version=1.0"));
  } : function (target2Id) {
    return getFullUrl("/api/devices/".concat(target2Id, "/primaryUsers/").concat(target1Id, "?api-version=1.0"));
  }, function () {
    return {
      method: 'DELETE'
    };
  });
};