var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
}; // tslint:disable: max-classes-per-file


export var SERVER_ERROR = 'ServerError';

var ServerError =
/** @class */
function (_super) {
  __extends(ServerError, _super);

  function ServerError(status, body) {
    var _this = _super.call(this, "Server error: Status: " + status + "\nBody:\n" + (body || '-')) || this;

    _this.name = SERVER_ERROR;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(_this, ServerError);
    }

    _this.status = status;
    _this.body = body;
    return _this;
  }

  return ServerError;
}(Error);

export { ServerError };
export var NETWORK_ERROR = 'NetworkError';

var NetworkError =
/** @class */
function (_super) {
  __extends(NetworkError, _super);

  function NetworkError() {
    var _this = _super.call(this, 'Network error') || this;

    _this.name = NETWORK_ERROR;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(_this, ServerError);
    }

    return _this;
  }

  return NetworkError;
}(Error);

export { NetworkError };
export var UNEXPECTED_ERROR = 'UnexpectedError';

var UnexpectedError =
/** @class */
function (_super) {
  __extends(UnexpectedError, _super);

  function UnexpectedError(error) {
    var _this = _super.call(this, process.env.NODE_ENV === 'production' ? error.name === SERVER_ERROR && String(error.status).charAt(0) === '4' ? "Error (" + error.status + "): " + error.body : "Unexpected error" + (error.name === SERVER_ERROR ? " (" + error.status + ")" : '') : error.message) || this;

    _this.name = UNEXPECTED_ERROR;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(_this, ServerError);
    }

    return _this;
  }

  return UnexpectedError;
}(Error);

export { UnexpectedError };
export var NOT_FOUND_ERROR = 'NotFoundError';

var NotFoundError =
/** @class */
function (_super) {
  __extends(NotFoundError, _super);

  function NotFoundError(notFoundName) {
    var _this = _super.call(this, notFoundName + " not found") || this;

    _this.name = NOT_FOUND_ERROR;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(_this, NotFoundError);
    }

    return _this;
  }

  return NotFoundError;
}(Error);

export { NotFoundError };
export var BATCH_REQUEST_ERROR = 'BatchRequestError';

var BatchRequestError =
/** @class */
function (_super) {
  __extends(BatchRequestError, _super);

  function BatchRequestError(failed, total, description) {
    var _this = _super.call(this, failed + " out of " + total + " " + description + " failed") || this;

    _this.name = NOT_FOUND_ERROR;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(_this, BatchRequestError);
    }

    return _this;
  }

  return BatchRequestError;
}(Error);

export { BatchRequestError };
export var asyncCallErrorHandlerWrapper = function asyncCallErrorHandlerWrapper(fn, errorHandler) {
  return function () {
    var args = [];

    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }

    return __awaiter(void 0, void 0, void 0, function () {
      var error_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , fn.apply(void 0, args)];

          case 1:
            return [2
            /*return*/
            , _a.sent()];

          case 2:
            error_1 = _a.sent();
            errorHandler(error_1);
            throw error_1;

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var expected = function expected(fn) {
  return asyncCallErrorHandlerWrapper(fn, function (error) {
    if (error.name === 'Error' || error.name === SERVER_ERROR) {
      console.error('To Unexpected', error); // tslint:disable-line: no-console

      throw new UnexpectedError(error);
    }
  });
};
export var notFoundable = function notFoundable(fn, name) {
  return asyncCallErrorHandlerWrapper(fn, function (error) {
    if (error.name === SERVER_ERROR && error.status === 404) {
      throw new NotFoundError(name);
    }
  });
};