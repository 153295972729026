import {
  ApplicationDeployment,
  Config as OpsCoreConfig,
  ListViewSchema,
  MultiListViewSchema,
  PackageDeployment,
} from '@sim/ops-core-web';
import { Config as SccmConfig, paths } from '@sim/sccm-web';
import { getFullUrl, Id } from '@sim/utils';

const multiListViewsSchema: MultiListViewSchema[] = [
  {
    id: 'sccm',
    // tslint:disable-next-line: object-literal-sort-keys
    filter: {
      url: getFullUrl(`/api/sccm/GlobalFilters`),
    },
    listViews: [
      {
        id: 'usersAndComputers',
        tabs: [
          {
            id: 'usersTab',
            // tslint:disable-next-line: object-literal-sort-keys
            columns: [
              {
                getCellValue: (row: any) => ({
                  link: paths.getUserPath(row.resourceId),
                  text: row.userName,
                }),
                isLink: true,
                name: 'userName',
                title: 'User Name',
              },
              {
                name: 'uniqueUserName',
                title: 'Unique User Name',
              },
              {
                name: 'mail',
                title: 'Email',
              },
            ],
            defaultSorting: [
              { columnName: 'userName', direction: 'asc' as 'asc' },
            ],
            displayName: 'Users',
            getRowId: (row: any) => row.resourceId,
            url: getFullUrl(`/odata/Users?api-version=3.0`),
          },
          {
            id: 'devicesTab',
            // tslint:disable-next-line: object-literal-sort-keys
            columns: [
              {
                getCellValue: (row: any) => ({
                  link: paths.getDevicePath(row.resourceId),
                  text: row.netbiosName,
                }),
                isLink: true,
                name: 'netbiosName',
                title: 'Name',
              },
              { name: 'operatingSystem', title: 'Operating System' },
            ],
            defaultSorting: [
              { columnName: 'netbiosName', direction: 'asc' as 'asc' },
            ],
            displayName: 'Devices',
            getRowId: (row: any) => row.resourceId,
            staticActions: [],
            url: getFullUrl(`/odata/Devices?api-version=3.0`),
            // contextActions: [
            //   {
            //     id: 'deviceProperties',
            //     itemType: 'Device',
            //     displayName: 'Properties',
            //     urlTemplate: `${viewsBaseUrl}/devices/{Device.id}`,
            //   },
            //   {
            //     id: 'refreshDevice',
            //     itemType: 'Device',
            //     displayName: 'Refresh',
            //     urlTemplate: `${viewsBaseUrl}/devices/{Device.id}/edit`,
            //   },
            //   {
            //     id: 'deletehDevice',
            //     itemType: 'Device',
            //     displayName: 'Delete',
            //     urlTemplate: `${viewsBaseUrl}/devices/{Device.id}?action=delete`,
            //   },
            // ],
          },
        ],
      },
      {
        id: 'applications',
        tabs: [
          {
            id: 'applicationsTab',
            // tslint:disable-next-line: object-literal-sort-keys
            columns: [
              {
                name: 'publisher',
                title: 'Manufacturer',
              },
              {
                getCellValue: (row: any) => ({
                  link: paths.getApplicationPath(row.modelId),
                  text: row.displayName,
                }),
                isLink: true,
                name: 'displayName',
                title: 'Product',
              },
              {
                name: 'version',
                title: 'Version',
              },
              {
                customFilterType: 'appState',
                initialFilterValue: '"Productive"',
                name: 'simAppState',
                title: 'Status',
              },
            ],
            defaultSorting: [
              { columnName: 'publisher', direction: 'asc' as 'asc' },
              { columnName: 'displayName', direction: 'asc' as 'asc' },
              { columnName: 'version', direction: 'asc' as 'asc' },
            ],
            disableMultiSelect: true,
            displayName: 'Applications',
            getRowId: (row: any) => row.modelId,
            url: getFullUrl(`/odata/Applications?api-version=3.0`),
          },
          {
            id: 'packagesTab',
            // tslint:disable-next-line: object-literal-sort-keys
            columns: [
              {
                name: 'manufacturer',
                title: 'Manufacturer',
              },
              {
                getCellValue: (row: any) => ({
                  link: paths.getPackagePath(row.packageId),
                  text: row.name,
                }),
                isLink: true,
                name: 'name',
                title: 'Product',
              },
              {
                name: 'version',
                title: 'Version',
              },
              {
                name: 'language',
                title: 'Language',
              },
              {
                customFilterType: 'appState',
                initialFilterValue: '"Productive"',
                name: 'packageState',
                title: 'State',
              },
            ],
            defaultSorting: [
              { columnName: 'manufacturer', direction: 'asc' as 'asc' },
              { columnName: 'name', direction: 'asc' as 'asc' },
              { columnName: 'version', direction: 'asc' as 'asc' },
            ],
            disableMultiSelect: true,
            displayName: 'Packages',
            getRowId: (row: any) => row.packageId,
            url: getFullUrl(`/odata/Packages?api-version=3.0`),
          },
        ],
      },
    ] as [ListViewSchema, ListViewSchema],
    multiListActions: [
      {
        id: 'deployDeviceApplication',
        tabIds: ['devicesTab', 'applicationsTab'] as [Id, Id],
        // tslint:disable-next-line: object-literal-sort-keys
        component: ApplicationDeployment,
        deploymentPath: '/api/DirectDeployments/batch',
        displayName: 'Deploy Application',
        leftConfig: {
          apiVersion: 'api-version=3.0',
          displayProperty: 'netbiosName',
          idProperty: 'resourceId',
          name: 'Devices',
          path: '/odata/Devices',
        },
        rightConfig: {
          apiVersion: 'api-version=3.0',
          displayProperty: 'displayName',
          idProperty: 'modelId',
          name: 'Applications',
          path: '/odata/Applications',
        },
      },
      {
        id: 'deployUserApplication',
        tabIds: ['usersTab', 'applicationsTab'] as [Id, Id],
        // tslint:disable-next-line: object-literal-sort-keys
        component: ApplicationDeployment,
        deploymentPath: '/api/DirectDeployments/batch',
        displayName: 'Deploy Application',
        leftConfig: {
          apiVersion: 'api-version=3.0',
          // deploymentPath: '/api/DirectDeployments/batch',
          displayProperty: 'userName',
          idProperty: 'resourceId',
          name: 'Users',
          path: '/odata/Users',
        },
        rightConfig: {
          apiVersion: 'api-version=3.0',
          // deploymentPath: '/api/DirectDeployments/batch',
          displayProperty: 'displayName',
          idProperty: 'modelId',
          name: 'Applications',
          path: '/odata/Applications',
        },
      },
      {
        id: 'deployUserPackage',
        tabIds: ['usersTab', 'packagesTab'] as [Id, Id],
        // tslint:disable-next-line: object-literal-sort-keys
        component: PackageDeployment,
        deploymentPath: '/api/PackageDeployments/to-user/batch',
        displayName: 'Deploy Package',
        leftConfig: {
          apiVersion: 'api-version=3.0',
          displayProperty: 'userName',
          idProperty: 'resourceId',
          name: 'Users',
          path: '/odata/Users',
        },
        rightConfig: {
          apiVersion: 'api-version=3.0',
          displayProperty: 'name',
          idProperty: 'packageId',
          name: 'Packages',
          path: '/odata/Packages',
        },
      },
      {
        id: 'deployDevicePackage',
        tabIds: ['devicesTab', 'packagesTab'] as [Id, Id],
        // tslint:disable-next-line: object-literal-sort-keys
        component: PackageDeployment,
        deploymentPath: '/api/PackageDeployments/to-device/batch',
        displayName: 'Deploy Package',
        leftConfig: {
          apiVersion: 'api-version=3.0',
          displayProperty: 'netbiosName',
          idProperty: 'resourceId',
          name: 'Devices',
          path: '/odata/Devices',
        },
        rightConfig: {
          apiVersion: 'api-version=3.0',
          displayProperty: 'name',
          idProperty: 'packageId',
          name: 'Packages',
          path: '/odata/Packages',
        },
      },
    ],
  },
  {
    id: 'airwatch',
    // tslint:disable-next-line: object-literal-sort-keys
    filter: {
      url: getFullUrl(`/api/AirwatchMain?api-version=2.0-beta`),
    },
    listViews: [
      {
        id: 'devices-and-users',
        tabs: [
          {
            id: 'devicesTab',
            // tslint:disable-next-line: object-literal-sort-keys
            columns: [
              {
                name: 'deviceFriendlyName',
                title: 'Name',
              },
              {
                name: 'model',
                title: 'Model',
              },
              {
                name: 'userName',
                title: 'User',
              },
              {
                name: 'platform',
                title: 'Platform',
              },
              {
                name: 'lastSeen',
                title: 'LastSeen',
              },
            ],
            displayName: 'Devices',
            getRowId: (row: any) => row.uuid,
            url: getFullUrl(`/odata/AirwatchDevices?api-version=2.0`),
          },
          {
            id: 'usersTab',
            // tslint:disable-next-line: object-literal-sort-keys
            columns: [
              {
                name: 'userName',
                title: 'User',
              },
              {
                name: 'email',
                title: 'Email',
              },
              {
                name: 'enrolledDevicesCount',
                title: 'Device count',
              },
            ],
            displayName: 'Users',
            getRowId: (row: any) => row.uuid,
            url: getFullUrl(`/odata/AirwatchUsers?api-version=2.0`),
          },
        ],
      },
      {
        id: 'applications',
        tabs: [
          {
            id: 'applicationsTab',
            // tslint:disable-next-line: object-literal-sort-keys
            columns: [
              {
                name: 'applicationName',
                title: 'Name',
              },
              {
                name: 'appVersion',
                title: 'Version',
              },
            ],
            displayName: 'Applications',
            getRowId: (row: any) => row.uuid,
            url: getFullUrl(`/odata/AirwatchApplications?api-version=2.0`),
          },
        ],
      },
    ],
    multiListActions: [],
  },
];

export const opsCoreConfig: OpsCoreConfig = {
  appStateFilters: [
    { value: '"Productive"', text: 'PROD' },
    { value: '"QA"', text: 'QA' },
  ],
  headerColor: [255, 255, 255],
  modules: [
    {
      id: 'sccm',
      // tslint:disable-next-line: object-literal-sort-keys
      displayName: 'Desktop Management',
      moduleType: 'multiListView' as 'multiListView',
    },
    // {
    //   id: 'airwatch',
    //   // tslint:disable-next-line: object-literal-sort-keys
    //   displayName: 'Mobile Device Mgmt',
    //   moduleType: 'multiListView' as 'multiListView',
    // },
  ],
  multiListViewsSchema,
  userInstallOptions: [
    {
      id: 1,
      // tslint:disable-next-line: object-literal-sort-keys
      displayName: 'Install available',
      installMode: 'install',
      required: false,
    },
  ],
};

export const sccmConfig: SccmConfig = {};
