export var getProfilePath = function getProfilePath() {
  return "/profile";
};
export var getEntityPath = function getEntityPath(entity, id) {
  return "/".concat(entity, "s/").concat(id);
};
export var getCreateDevicePath = function getCreateDevicePath() {
  return "/devices/create";
};
export var getMassDeviceImportPath = function getMassDeviceImportPath() {
  return "/devices/mass-import";
};
export var getDevicePath = function getDevicePath(deviceId) {
  return "/devices/".concat(deviceId);
};
export var getEditDevicePath = function getEditDevicePath(deviceId) {
  return "/devices/".concat(deviceId, "/edit");
};
export var getDevicePrimaryUsersPath = function getDevicePrimaryUsersPath(deviceId) {
  return "/devices/".concat(deviceId, "/primary-users");
};
export var getAddDevicePrimaryUsersPath = function getAddDevicePrimaryUsersPath(deviceId) {
  return "/devices/".concat(deviceId, "/add-primary-users");
};
export var getDeviceAllApplicationDeploymentsPath = function getDeviceAllApplicationDeploymentsPath(deviceId) {
  return "/devices/".concat(deviceId, "/all-application-deployments");
};
export var getDeviceDirectApplicationDeploymentsPath = function getDeviceDirectApplicationDeploymentsPath(deviceId) {
  return "/devices/".concat(deviceId, "/direct-application-deployments");
};
export var getDeviceAllPackageDeploymentsPath = function getDeviceAllPackageDeploymentsPath(deviceId) {
  return "/devices/".concat(deviceId, "/all-package-deployments");
};
export var getDeviceDirectPackageDeploymentsPath = function getDeviceDirectPackageDeploymentsPath(deviceId) {
  return "/devices/".concat(deviceId, "/direct-package-deployments");
};
export var getDeviceSystemVariablesPath = function getDeviceSystemVariablesPath(deviceId) {
  return "/devices/".concat(deviceId, "/system-variables");
};
export var getUserPath = function getUserPath(userId) {
  return "/users/".concat(userId);
};
export var getUserPrimaryDevicesPath = function getUserPrimaryDevicesPath(userId) {
  return "/users/".concat(userId, "/primary-devices");
};
export var getAddUserPrimaryDevicesPath = function getAddUserPrimaryDevicesPath(userId) {
  return "/users/".concat(userId, "/add-primary-devices");
};
export var getUserAllApplicationDeploymentsPath = function getUserAllApplicationDeploymentsPath(userId) {
  return "/users/".concat(userId, "/all-application-deployments");
};
export var getUserDirectApplicationDeploymentsPath = function getUserDirectApplicationDeploymentsPath(userId) {
  return "/users/".concat(userId, "/direct-application-deployments");
};
export var getUserAllPackageDeploymentsPath = function getUserAllPackageDeploymentsPath(userId) {
  return "/users/".concat(userId, "/all-package-deployments");
};
export var getUserDirectPackageDeploymentsPath = function getUserDirectPackageDeploymentsPath(userId) {
  return "/users/".concat(userId, "/direct-package-deployments");
};
export var getApplicationPath = function getApplicationPath(applicationId) {
  return "/applications/".concat(applicationId);
};
export var getApplicationDeploymentTypesPath = function getApplicationDeploymentTypesPath(applicationId) {
  return "/applications/".concat(applicationId, "/deployment-types");
};
export var getApplicationDirectUserDeploymentsPath = function getApplicationDirectUserDeploymentsPath(applicationId) {
  return "/applications/".concat(applicationId, "/direct-user-deployments");
};
export var getApplicationDirectDeviceDeploymentsPath = function getApplicationDirectDeviceDeploymentsPath(applicationId) {
  return "/applications/".concat(applicationId, "/direct-device-deployments");
};
export var getPackagePath = function getPackagePath(packageId) {
  return "/packages/".concat(packageId);
};
export var getPackageDirectUserDeploymentsPath = function getPackageDirectUserDeploymentsPath(packageId) {
  return "/packages/".concat(packageId, "/direct-user-deployments");
};
export var getPackageDirectDeviceDeploymentsPath = function getPackageDirectDeviceDeploymentsPath(packageId) {
  return "/packages/".concat(packageId, "/direct-device-deployments");
};
export var getMassDeploymentsListPath = function getMassDeploymentsListPath(_ref, sourceId, keepLocationSearch) {
  var sourceType = _ref.sourceType,
      targetType = _ref.targetType;
  return "/".concat(sourceType, "s/").concat(sourceId, "/mass-").concat(targetType, "-deployments").concat(keepLocationSearch ? window.location.search : '');
};
export var getMassDeploymentPath = function getMassDeploymentPath(_ref2, sourceId, deploymentId, keepLocationSearch) {
  var sourceType = _ref2.sourceType,
      targetType = _ref2.targetType;
  return "/".concat(sourceType, "s/").concat(sourceId, "/mass-").concat(targetType, "-deployments/").concat(deploymentId != null ? deploymentId : 'create').concat(keepLocationSearch ? window.location.search : '');
};
export var getAddMassDeploymentTargetsPath = function getAddMassDeploymentTargetsPath(_ref3, sourceId, deploymentId, keepLocationSearch) {
  var sourceType = _ref3.sourceType,
      targetType = _ref3.targetType;
  return "/".concat(sourceType, "s/").concat(sourceId, "/mass-").concat(targetType, "-deployments/").concat(deploymentId, "/add-").concat(targetType, "s").concat(keepLocationSearch ? window.location.search : '');
};
export var getMassAddMassDeploymentTargetsPath = function getMassAddMassDeploymentTargetsPath(_ref4, sourceId, deploymentId, keepLocationSearch) {
  var sourceType = _ref4.sourceType,
      targetType = _ref4.targetType;
  return "/".concat(sourceType, "s/").concat(sourceId, "/mass-").concat(targetType, "-deployments/").concat(deploymentId, "/mass-add-").concat(targetType, "s").concat(keepLocationSearch ? window.location.search : '');
};
export var getAddMassDeploymentQueryPath = function getAddMassDeploymentQueryPath(_ref5, sourceId, deploymentId, keepLocationSearch) {
  var sourceType = _ref5.sourceType,
      targetType = _ref5.targetType;
  return "/".concat(sourceType, "s/").concat(sourceId, "/mass-").concat(targetType, "-deployments/").concat(deploymentId, "/add-queries").concat(keepLocationSearch ? window.location.search : '');
};
export var getDeployPath = function getDeployPath() {
  return "/deploy";
};
export var getPackageDeployPath = function getPackageDeployPath() {
  return '/package-deploy';
};