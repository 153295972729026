import { DeploymentCategory, DeploymentMode, DeploymentOfferType, TargetType } from './types';
export var isTargetType = function isTargetType(entityType) {
  return entityType === TargetType.User || entityType === TargetType.Device;
};
export var deploymentModeText = function deploymentModeText(mode) {
  switch (mode) {
    case DeploymentMode.Install:
      return 'Install';

    case DeploymentMode.Uninstall:
      return 'Uninstall';

    default:
      return 'Unknown';
  }
};
export var deploymentOfferTypeText = function deploymentOfferTypeText(offerType) {
  switch (offerType) {
    case DeploymentOfferType.Required:
      return 'Mandatory';

    case DeploymentOfferType.Available:
      return 'Available';

    default:
      return 'Unknown';
  }
};
export var deploymentCategoryText = function deploymentCategoryText(category) {
  switch (category) {
    case DeploymentCategory.Pilot:
      return 'Pilot';

    case DeploymentCategory.Rollout:
      return 'Rollout';

    default:
      return 'Unknown';
  }
}; // tslint:disable-next-line: variable-name

export var deploymentCategory_Url2Enum = function deploymentCategory_Url2Enum(category) {
  switch (category) {
    case 'pilot':
      return DeploymentCategory.Pilot;

    case 'rollout':
      return DeploymentCategory.Rollout;

    default:
      return undefined;
  }
};