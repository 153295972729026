import { Icon as AntdIcon, Select as AntdSelect, Spin as AntdSpin } from 'antd';
import React, { useCallback, useRef } from 'react';
import { async, useAsyncData } from 'react-async-utils';
var loadingIndicator = React.createElement(AntdSpin, {
  indicator: React.createElement(AntdIcon, {
    type: "loading",
    spin: true
  })
});
export var RemoteSelect = function RemoteSelect(_a) {
  var controlType = _a.controlType,
      name = _a.name,
      onChange = _a.onChange,
      placeholder = _a.placeholder,
      readonly = _a.readonly,
      setFieldValue = _a.setFieldValue,
      value = _a.value;
  var getOptions = controlType.getOptions,
      getOptionValue = controlType.getOptionValue,
      getOptionText = controlType.getOptionText,
      isOptionDisabled = controlType.isOptionDisabled,
      searchable = controlType.searchable;

  var _b = useAsyncData(getOptions),
      asyncOptions = _b[0],
      triggerGetOptions = _b[1];

  var options = async.payloadOrUndefined(asyncOptions);
  var loading = async.isInProgress(asyncOptions);
  var handleChange = useCallback(function (newValue) {
    onChange ? onChange(newValue) : setFieldValue(name, newValue);
  }, [onChange, setFieldValue]);
  var searchTimeoutIdRef = useRef(undefined);
  var handleSearch = useCallback(function (search) {
    var searchTimeoutId = searchTimeoutIdRef.current;

    if (searchTimeoutId != null) {
      clearTimeout(searchTimeoutId);
    }

    searchTimeoutIdRef.current = setTimeout(function () {
      triggerGetOptions(search);
    }, 500);
  }, [triggerGetOptions, searchTimeoutIdRef]);

  if (readonly) {
    if (options) {
      var selectedOption = options.find(function (o) {
        return getOptionValue ? getOptionValue(o) : o === value;
      });
      return selectedOption ? getOptionText ? getOptionText(selectedOption) : selectedOption : 'Unknown';
    } else {
      return loadingIndicator;
    }
  }

  return React.createElement(AntdSelect, {
    id: name,
    disabled: readonly,
    value: value,
    placeholder: async.render(asyncOptions, 'Options', function () {
      return null;
    }, function () {
      return 'Loading...';
    }, function () {
      return placeholder || 'Select...';
    }, function () {
      return 'Error';
    }),
    showSearch: searchable,
    filterOption: false,
    notFoundContent: loading ? loadingIndicator : 'No Data',
    onSearch: handleSearch,
    onChange: handleChange
  }, options && !loading && options.map(function (option) {
    var optionValue = getOptionValue ? getOptionValue(option) : option;
    return React.createElement(AntdSelect.Option, {
      key: optionValue,
      value: optionValue,
      disabled: isOptionDisabled ? isOptionDisabled(option) : false
    }, getOptionText ? getOptionText(option) : option);
  }));
};