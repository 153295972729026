export var SourceType;

(function (SourceType) {
  SourceType["Package"] = "package";
  SourceType["Application"] = "application";
})(SourceType || (SourceType = {}));

export var TargetType;

(function (TargetType) {
  TargetType["User"] = "user";
  TargetType["Device"] = "device";
})(TargetType || (TargetType = {}));

export var DeploymentMode;

(function (DeploymentMode) {
  DeploymentMode["Install"] = "Install";
  DeploymentMode["Uninstall"] = "Uninstall";
})(DeploymentMode || (DeploymentMode = {}));

export var DeploymentOfferType;

(function (DeploymentOfferType) {
  DeploymentOfferType["Required"] = "Mandatory";
  DeploymentOfferType["Available"] = "Optional";
})(DeploymentOfferType || (DeploymentOfferType = {}));

export var DeploymentCategory;

(function (DeploymentCategory) {
  DeploymentCategory["Pilot"] = "Pilot";
  DeploymentCategory["Rollout"] = "Rollout";
})(DeploymentCategory || (DeploymentCategory = {}));