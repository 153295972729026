import {
  ConfigContext as OpsCoreConfigContext,
  MainLayout,
} from '@sim/ops-core-web';
import {
  ConfigContext as SccmConfigContext,
  ModuleRoutes as SccmModuleRoutes,
  routerPaths as sccmRouterPaths,
} from '@sim/sccm-web';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { opsCoreConfig, sccmConfig } from '../config/hcConfig';
import {
  AuthenticationProvider,
  Status,
  useAuthentication,
} from 'glow-react/es/authentication';

const PUBLIC_URL = process.env.PUBLIC_URL || '';

export default function App() {
  return (
    <AuthenticationProvider>
      <Main />
    </AuthenticationProvider>
  );
}

function Main() {
  const { status, profile } = useAuthentication();

  React.useEffect(() => {
    if (
      status == Status.loggedOut &&
      document.location.href != '/simaccount/signin'
    ) {
      //if in teams, already logged in
      document.location.href = '/simaccount/signin';
      //window.location.replace()
    }
  }, [status]);

  return (
    <div style={{ minHeight: '100vh' }}>
      <BrowserRouter basename={PUBLIC_URL}>
        <Switch>
          <Route
            exact
            path={sccmRouterPaths}
            render={() => (
              <SccmConfigContext.Provider value={sccmConfig}>
                <SccmModuleRoutes />
              </SccmConfigContext.Provider>
            )}
          />
          <Route
            key="home"
            render={() => (
              <OpsCoreConfigContext.Provider value={opsCoreConfig}>
                <MainLayout moduleSchemas={opsCoreConfig.modules} />
              </OpsCoreConfigContext.Provider>
            )}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}
