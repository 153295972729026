import { safeGet } from './safe';
export var propHasMinLength = function propHasMinLength(array, propName, component) {
  var minLength = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1;

  if (array.length < minLength) {
    throw new Error("Invalid prop `".concat(propName, "` supplied to `").concat(component.constructor.name, "`, expected an array with minimum length ").concat(minLength, ", received `").concat(JSON.stringify(array), "`"));
  }
};
export var openPopup = function openPopup(url) {
  return window.open(url, '', 'resizable=1,scrollbars=1,width=800,height=800,top=100,left=100');
};
export var idMap = function idMap(array) {
  return array.reduce(function (map, item) {
    return map.set(item.id, item);
  }, new Map());
}; // export const deepIdMap = (
//   root: mixed,
//   keys: Array<string>,
//   path: string = ''
// ): any => {
//   let result;
//   if (Array.isArray(root)) {
//     result = root.map((item, index) =>
//       deepIdMap(item, keys, `${path}/${index}`)
//     );
//   } else if (typeof root === 'object' && root != null) {
//     const safeRoot = root;
//     result = Object.keys(root).reduce((acc, key) => {
//       const value = deepIdMap(safeRoot[key], keys, `${path}/${key}`);
//       if (keys.includes(key)) {
//         acc[key] = idMap(value);
//       } else {
//         acc[key] = value;
//       }
//       return acc;
//     }, {});
//   } else {
//     result = root;
//   }
//   return result;
// };

export var getItemsPropsDict = function getItemsPropsDict(targetItem, originItem) {
  var paramsDict = new Map();
  Object.keys(targetItem).reduce(function (map, key) {
    return map.set("".concat(targetItem.itemType, ".").concat(key), String(targetItem[key]));
  }, paramsDict);
  var safeOriginItem = originItem;
  safeOriginItem && Object.keys(safeOriginItem).reduce(function (map, key) {
    return map.set("".concat(safeOriginItem.itemType, ".").concat(key), String(safeOriginItem[key]));
  }, paramsDict);
  return paramsDict;
};
export var replaceTemplate = function replaceTemplate(template, dictionary) {
  return Array.from(dictionary.keys()).reduce(function (dictionaryTemplate, key) {
    return dictionaryTemplate.replace("{".concat(key, "}"), safeGet(dictionary, key));
  }, template);
};
export var getErrorMessage = function getErrorMessage(name) {
  var errorMessage;

  if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'production') {
    errorMessage = 3; //  <span style={{ color: 'red' }}>
    // {`Error in ${name}.`}
    // </span>;
  } else {
    var arts = ['╭∩╮(Ο_Ο)╭∩╮', '¯\\_(ツ)_/¯', '(╯°□°）╯︵ ┻━┻', '┏(-_-)┛┗(-_- )┓┗(-_-)┛┏(-_-)┓'];
    errorMessage = 3; // <span style={{ color: 'red', whiteSpace: 'pre' }}>
    //   {`Error in ${name}:      ${
    //     arts[Math.floor(Math.random() * arts.length)]
    //   }`}
    // </span>
  }

  return errorMessage;
};