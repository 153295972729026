import { Button as AntdButton } from 'antd';
import { Form as FormikForm } from 'formik';
import React from 'react';
import { SchemaFormikField } from './SchemaFormikField';
export var SchemaFormikForm = function SchemaFormikForm(_a) {
  var formSchema = _a.formSchema,
      setFieldValue = _a.setFieldValue,
      disableSubmit = _a.disableSubmit;
  return React.createElement(FormikForm, null, formSchema.map(function (fielSchema) {
    return React.createElement(SchemaFormikField, {
      key: fielSchema.name,
      fieldSchema: fielSchema,
      setFieldValue: setFieldValue
    });
  }), React.createElement(AntdButton, {
    style: {
      marginTop: '1rem'
    },
    htmlType: "submit",
    disabled: disableSubmit
  }, "Submit"));
};