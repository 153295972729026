var PUBLIC_URL = process.env.PUBLIC_URL || '';
export var getFullUrl = function getFullUrl(url) {
  return "" + PUBLIC_URL + url;
};
export var arrayToPage = function arrayToPage(array) {
  return array ? {
    items: array,
    totalCount: array.length
  } : {
    items: [],
    totalCount: 0
  };
};
export var odataPageToPage = function odataPageToPage(odataPage) {
  return {
    items: odataPage.value,
    totalCount: odataPage['@odata.count'] || 0
  };
};
export function notEmpty(value) {
  return value != null;
}
export var capitalizeFirstLetter = function capitalizeFirstLetter(text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
};
export var delay = function delay(value, time) {
  if (time === void 0) {
    time = 1000;
  }

  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(value);
    }, time);
  });
};